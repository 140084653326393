import { Route, Routes } from "react-router-dom";

import { routes } from "./constants/Routes";
import ProtectedRoute from "./components/General/ProtectedRoute/ProtectedRoute";
import Layout from "./components/General/Layout/Layout";

/**
 * App component.
 */
export default function App() {
  return (
    <Routes>
      {routes.map(({ path, exact, component: Component, protectedRoute }) => {
        if (protectedRoute) {
          return (
            <Route
              key={path}
              path={path}
              exact={exact}
              element={
                <ProtectedRoute>
                  <Layout>
                    <Component />
                  </Layout>
                </ProtectedRoute>
              }
            />
          );
        } else {
          return <Route path={path} exact={exact} element={<Component />} />;
        }
      })}
    </Routes>
  );
}
