import LoginPage from "../pages/LoginPage/LoginPage";
import HomePage from "../pages/HomePage/HomePage";
import DestinationsOverviewPage from "../pages/DestinationsOverviewPage/DestinationsOverviewPage";
import DestinationDetailPage from "../pages/DestinationDetailPage/DestinationDetailPage";
import CreateDestinationPage from "../pages/CreateDestinationPage/CreateDestinationPage";

export const routes = [
  {
    path: "/login",
    exact: true,
    component: LoginPage,
    protectedRoute: false,
  },
  {
    path: "/",
    exact: true,
    component: HomePage,
    protectedRoute: true,
  },
  {
    path: "/destinations",
    exact: true,
    component: DestinationsOverviewPage,
    protectedRoute: true,
  },
  {
    path: "/destination/:id",
    exact: true,
    component: DestinationDetailPage,
    protectedRoute: true,
  },
  {
    path: "/create-destination",
    exact: true,
    component: CreateDestinationPage,
    protectedRoute: true,
  },
];
