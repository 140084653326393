/**
 * Input field component.
 *
 * @param {string} label The input label.
 * @param {string} name The name of the input.
 * @param {string} id The ID of the input field.
 * @param {function} onChange The function triggered when input value changes.
 * @param {string} className The function triggered when input value changes.
 * @param {object} values The values to select from.
 */
export default function Select({
  label,
  name,
  id,
  onChange,
  className,
  values,
  ...props
}) {
  return (
    <div className={`form-select ${className ?? ""}`}>
      <label className="form-select__label" htmlFor={id}>
        {label}
      </label>
      <select
        className="form-select__select"
        id={id}
        name={name}
        onChange={(e) => onChange(e.target.value)}
        {...props}
      >
        {Object.keys(values).map((key) => (
          <option key={key} value={key}>
            {values[key]}
          </option>
        ))}
      </select>
    </div>
  );
}
