import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import HeadingWrapper from "../../components/General/HeadingWrapper/HeadingWrapper";
import CategoryIcon from "../../components/Icons/CategoryIcon";
import EditIcon from "../../components/Icons/EditIcon";
import LocationIcon from "../../components/Icons/LocationIcon";
import ShareIcon from "../../components/Icons/ShareIcon";
import { getDataByID } from "../../dao/firestore";
import { destinationsCollection } from "../../constants/Firebase";
import { countries } from "../../constants/Countries";
import { useTranslation } from "react-i18next";
import { priceMapping } from "../../constants/Map";

/**
 * The destination detail page.
 */
export default function DestinaionDetailPage() {
  const { id } = useParams();
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState("description");
  const [destination, setDestination] = useState([]);

  useEffect(() => {
    /**
     * Get the data of the current destination.
     */
    async function getDestinations() {
      await getDataByID(destinationsCollection, id).then((res) => {
        setDestination(res);
      });
    }

    getDestinations();
  }, [id]);

  return (
    <div className="destination">
      <HeadingWrapper title={destination?.name} />

      <div className="destination__details-wrapper">
        <div className="destination__details-wrapper--items">
          {destination?.country && (
            <span>
              <img
                src={`https://flagsapi.com/${destination?.country}/flat/24.png`}
                alt={`Flag of ${countries[destination?.country]}`}
              />
              <p>{countries[destination?.country]}</p>
            </span>
          )}
          <span>
            <LocationIcon />
            <p>{destination?.location}</p>
          </span>
          {destination?.category && (
            <span>
              <CategoryIcon />
              <p>{destination?.category}</p>
            </span>
          )}
        </div>
        <div className="destination__details-wrapper--items">
          <span>
            <ShareIcon />
            <p>Share</p>
          </span>
          <span>
            <EditIcon />
            <p>Edit</p>
          </span>
        </div>
      </div>

      <div className="destination__image-wrapper">
        <div className="destination__image-wrapper--image">
          <img
            alt=""
            src="https://thumbs.dreamstime.com/b/incredibly-beautiful-sunset-sun-lake-sunrise-landscape-panorama-nature-sky-amazing-colorful-clouds-fantasy-design-115177001.jpg"
          />
        </div>
        <div className="destination__image-wrapper--image">
          <img
            alt=""
            src="https://thumbs.dreamstime.com/b/incredibly-beautiful-sunset-sun-lake-sunrise-landscape-panorama-nature-sky-amazing-colorful-clouds-fantasy-design-115177001.jpg"
          />
        </div>
        <div className="destination__image-wrapper--image">
          <img
            alt=""
            src="https://thumbs.dreamstime.com/b/incredibly-beautiful-sunset-sun-lake-sunrise-landscape-panorama-nature-sky-amazing-colorful-clouds-fantasy-design-115177001.jpg"
          />
        </div>
        <div className="destination__image-wrapper--image">
          <img
            alt=""
            src="https://thumbs.dreamstime.com/b/incredibly-beautiful-sunset-sun-lake-sunrise-landscape-panorama-nature-sky-amazing-colorful-clouds-fantasy-design-115177001.jpg"
          />
        </div>
        <div className="destination__image-wrapper--image">
          <img
            alt=""
            src="https://thumbs.dreamstime.com/b/incredibly-beautiful-sunset-sun-lake-sunrise-landscape-panorama-nature-sky-amazing-colorful-clouds-fantasy-design-115177001.jpg"
          />
        </div>
      </div>

      <div className="destination__information-wrapper">
        <div className="destination__information-wrapper--tabs">
          <div className="destination__information-wrapper--tabs__items">
            <h6
              className={activeTab === "description" ? "active" : ""}
              onClick={() => setActiveTab("description")}
            >
              Description
            </h6>
            <h6
              className={activeTab === "links" ? "active" : ""}
              onClick={() => setActiveTab("links")}
            >
              Links
            </h6>
          </div>
          {activeTab === "description" && <p>{destination?.description}</p>}
          {activeTab === "links" && destination?.links && (
            <ul>
              {destination?.links?.map((link) => (
                <li>
                  <a
                    href={
                      link?.url.includes("https://")
                        ? link?.url
                        : `https://${link?.url}`
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {link?.title}
                  </a>
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="destination__information-wrapper--card">
          <h5>Details</h5>
          <div className="destination__information-wrapper--card__items">
            <p>Price</p>
            <p>{priceMapping[destination?.price]}</p>
          </div>
          <div className="destination__information-wrapper--card__items">
            <p>{t("visited")}</p>
            <p>{destination?.visited ? "Yes" : "No"}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
