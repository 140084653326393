import { useState } from "react";

import MenuIcon from "../../Icons/MenuIcon";
import { Link } from "react-router-dom";

/**
 * Header navigation.
 *
 * @param {string} logoUrl The URL of the logo.
 * @param {string} logoAlt The alt text of the logo.
 * @param {JSX.Element} children The children.
 */
export default function Header({ logoUrl, logoAlt, children }) {
  const [menuOpened, setMenuOpened] = useState(false);

  return (
    <div className="header">
      <Link to="/" className="header__logo-wrapper">
        <img
          src={logoUrl}
          alt={logoAlt}
          className="header__logo-wrapper--logo"
        />
      </Link>
      <div className="header__navigation">
        <button
          className="header__navigation--menu"
          onClick={() => setMenuOpened(!menuOpened)}
        >
          <MenuIcon />
        </button>
        <div
          className={`header__navigation--items ${
            menuOpened ? "menu-opened" : ""
          }`}
        >
          {children}
        </div>
      </div>
    </div>
  );
}
