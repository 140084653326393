/**
 * Input field component.
 *
 * @param {string} label The input label.
 * @param {string} type The input type.
 * @param {string} name The name of the input.
 * @param {string} id The ID of the input field.
 * @param {function} onChange The function triggered when input value changes.
 * @param {string} className The function triggered when input value changes.
 */
export default function Input({
  label,
  type,
  name,
  id,
  onChange,
  className,
  ...props
}) {
  return (
    <div className={`form-input ${className ?? ""}`} data-testid="form-input">
      <label className="form-input__label" htmlFor={id}>
        {label}
      </label>
      <input
        className="form-input__input"
        id={id}
        name={name}
        type={type}
        onChange={(e) => onChange(e.target.value)}
        data-testid={id}
        {...props}
      />
    </div>
  );
}
