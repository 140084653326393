import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { useTranslation } from "react-i18next";

import { auth } from "../../../firebase";
import Input from "../../Form/Input/Input";
import useUser from "../../../hooks/useUser";

/**
 * The sign up form.
 */
export default function SignupForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setUser } = useUser();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const onSubmit = async (e) => {
    e.preventDefault();

    await createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        setUser(userCredential.user);
        navigate("/");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(errorCode, errorMessage);
      });
  };

  return (
    <form className="signup-form">
      <Input
        label={t("emailAddress")}
        type="email"
        name="email"
        id="email-address"
        onChange={setEmail}
        required
      />

      <Input
        label={t("password")}
        type="password"
        name="password"
        id="password"
        onChange={setPassword}
        required
      />

      <button
        className="button button-primary"
        type="submit"
        onClick={onSubmit}
      >
        {t("signUp")}
      </button>
    </form>
  );
}
