import { Link } from "react-router-dom";

import colors from "../../../assets/styles/general/_colors.scss";

/**
 * Destinations card for in an overview.
 *
 * @param {string} name Name of the destination.
 * @param {string} cateogory Category of the destination.
 * @param {string} id Unique ID of the destination.
 * @param {string} imageUrl The background image URL of the destination.
 */
export default function DestinationCard({ name, category, id, imageUrl }) {
  return (
    <Link
      to={`/destination/${id}`}
      className="destination-card"
      style={{
        backgroundImage: `linear-gradient(to bottom, transparent, ${colors.blackColor}), url('${imageUrl}')`,
      }}
    >
      <span className="destination-card__category-wrapper">
        <p className="destination-card__category-wrapper--category">
          {category}
        </p>
      </span>
      <h5 className="destination-card__title">{name}</h5>
    </Link>
  );
}
