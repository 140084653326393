import { useTranslation } from "react-i18next";

import Input from "../Input/Input";
import PlusIcon from "../../Icons/PlusIcon";
import colors from "../../../assets/styles/general/_colors.scss";
import MinusIcon from "../../Icons/MinusIcon";

const titleType = "title";
const urlType = "url";

export default function LinksInput({ links, setLinks }) {
  const { t } = useTranslation();

  /**
   * Add a link item.
   */
  function addItem() {
    setLinks((prevLinks) => [...prevLinks, { title: "", url: "" }]);
  }

  /**
   * Remove a link item.
   */
  function removeItem(index) {
    setLinks((prevState) => {
      const newLinks = prevState.filter((linkItem, i) => {
        return i !== index;
      });

      return newLinks;
    });
  }

  /**
   *
   * @param {string} value The new input value.
   * @param {number} index The index of the links array.
   * @param {string} type The type to update.
   */
  function updateLinks(value, index, type) {
    const newLinks = links;
    if (type === titleType) {
      newLinks[index] = { title: value, url: newLinks[index].url };
    }

    if (type === urlType) {
      newLinks[index] = { title: newLinks[index].title, url: value };
    }

    setLinks(newLinks);
  }

  return (
    <div className="links-input">
      {links.length > 0 && (
        <div className="links-input__input-fields">
          {links?.map((link, index) => (
            <div key={index} className="links-input__input-fields--wrapper">
              <button
                className="links-input__input-fields--wrapper__remove"
                onClick={() => removeItem(index)}
              >
                <MinusIcon />
              </button>
              <div className="links-input__input-fields--wrapper__inputs">
                <Input
                  label={t("linkTitle")}
                  type="text"
                  name="linkt-title"
                  id="linkt-title"
                  onChange={(value) => updateLinks(value, index, titleType)}
                  required
                />
                <Input
                  label={t("linkUrl")}
                  type="text"
                  name="link-url"
                  id="link-url"
                  onChange={(value) => updateLinks(value, index, urlType)}
                  required
                />
              </div>
            </div>
          ))}
        </div>
      )}
      <div className="links-input__add-item">
        <button className="btn btn-secondary" onClick={() => addItem()}>
          <PlusIcon fillColor={colors.whiteColor} />
          {t("addLink")}
        </button>
      </div>
    </div>
  );
}
