/**
 * Textarea field component.
 *
 * @param {string} label The input label.
 * @param {string} type The input type.
 * @param {string} name The name of the input.
 * @param {string} id The ID of the input field.
 * @param {function} onChange The function triggered when input value changes.
 * @param {string} className The function triggered when input value changes.
 */
export default function Textarea({
  label,
  type,
  name,
  id,
  onChange,
  className,
  ...props
}) {
  return (
    <div className={`form-textarea ${className ?? ""}`}>
      <label className="form-textarea__label" htmlFor={id}>
        {label}
      </label>
      <textarea
        className="form-textarea__textarea"
        id={id}
        name={name}
        type={type}
        onChange={(e) => onChange(e.target.value)}
        {...props}
      ></textarea>
    </div>
  );
}
