import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";

import LoginForm from "../../components/Login/LoginForm/LoginForm";
import SignupForm from "../../components/Login/SignUpForm/SignUpForm";
import GoogleLoginButton from "../../components/Login/GoogleLoginButton/GoogleLoginButton";
import useUser from "../../hooks/useUser";

/**
 * Login page.
 */
export default function LoginPage() {
  const { t } = useTranslation();
  const { user } = useUser();
  const [showSignUp, setShowSignUp] = useState(false);

  if (user) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className="login-page">
      <div className="login-page__form-wrapper">
        <div className="login-page__form-wrapper--logo">
          <h1>Logo here</h1>
        </div>
        {showSignUp && (
          <>
            <SignupForm />
            <p className="login-page__form-wrapper--switch-form">
              {t("haveAccount")}{" "}
              <span onClick={() => setShowSignUp(false)}>{t("signIn")}!</span>
            </p>
          </>
        )}
        {!showSignUp && (
          <>
            <div className="login-page__form-wrapper--buttons">
              <GoogleLoginButton />
            </div>
            <p className="login-page__form-wrapper--divider">
              <span>OR</span>
            </p>
            <LoginForm />
            <p className="login-page__form-wrapper--switch-form">
              {t("noAccount")}{" "}
              <span onClick={() => setShowSignUp(true)}>{t("signUp")}!</span>
            </p>
          </>
        )}
      </div>
    </div>
  );
}
