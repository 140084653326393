/**
 * Get data for a specifc place ID.
 *
 * @param {string} placeId The place ID.
 */
export async function getDataByPlaceID(placeId) {
  return await fetch(
    `https://places.googleapis.com/v1/places/${placeId}?fields=displayName,formattedAddress,editorialSummary,priceLevel,addressComponents&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
  )
    .then((res) => {
      return res.json();
    })
    .catch((err) => {
      // TODO: handle error
      console.log("debug err", err);
    });
}
