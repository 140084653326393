export const nlTranslations = {
  emailAddress: "Email adres",
  password: "Wachtwoord",
  login: "Log in",
  noAccount: "Nog geen account?",
  signUp: "Registreer",
  haveAccount: "Al een account?",
  signIn: "Log in",
  account: "Account",
  create: "Maak",
  itinerary: "Itinerary",
  destination: "Bestemming",
  destinationa: "Bestemmingen",
  allDestinations: "Zie alle bestemingen",
  destinationName: "Bestemming naam",
  address: "Adres",
  price: "Prijs",
  visited: "Bezocht",
  category: "Categorie",
  country: "Land",
  description: "Beschrijving",
  linkTitle: "Link titel",
  linkUrl: "Link URL",
  addLink: "Voeg link toe",
};
