import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import DestinationCard from "../../components/Cards/DestinationCard/DestinationCard";
import HeadingWrapper from "../../components/General/HeadingWrapper/HeadingWrapper";
import { getDataByUserID } from "../../dao/firestore";
import { destinationsCollection } from "../../constants/Firebase";
import useUser from "../../hooks/useUser";

/**
 * The home page.
 */
export default function HomePage() {
  const { t } = useTranslation();
  const { user } = useUser();

  const [destinations, setDestinations] = useState([]);

  /**
   * Get all the destinations.
   */
  async function getDestinations() {
    await getDataByUserID(destinationsCollection, user?.uid).then((res) => {
      setDestinations(res);
    });
  }

  useEffect(() => {
    getDestinations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="home" data-testid="home">
      <HeadingWrapper
        title={t("destinations")}
        linkText={t("allDestinations")}
        linkUrl="/destinations"
      />
      <div className="card-wrapper">
        {destinations.length > 0 &&
          destinations?.map((destination) => (
            <DestinationCard
              key={destination?.id}
              id={destination?.id}
              name={destination?.name}
              category={destination?.category}
              imageUrl={destination?.imageUrl}
            />
          ))}
      </div>
    </div>
  );
}
