import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import HeadingWrapper from "../../components/General/HeadingWrapper/HeadingWrapper";
import DestinationCard from "../../components/Cards/DestinationCard/DestinationCard";
import { getData } from "../../dao/firestore";
import { destinationsCollection } from "../../constants/Firebase";

/**
 * The destinations overview page.
 */
export default function DestinationsOverviewPage() {
  const { t } = useTranslation();
  const [destinations, setDestinations] = useState([]);

  /**
   * Get all the destinations.
   */
  async function getDestinations() {
    await getData(destinationsCollection).then((res) => {
      setDestinations(res);
    });
  }

  useEffect(() => {
    getDestinations();
  }, []);

  return (
    <div className="destinations-overview">
      <HeadingWrapper title={t("destinations")} />
      <div className="card-wrapper">
        {destinations?.map((destination) => (
          <DestinationCard
            key={destination?.id}
            id={destination?.id}
            name={destination?.name}
            category={destination?.category}
            imageUrl={destination?.imageUrl}
          />
        ))}
      </div>
    </div>
  );
}
