import { collection, addDoc, getDocs } from "firebase/firestore";

import { db } from "../firebase";

/**
 * Add datato firestore.
 *
 * @param {string} collectionName The name of the collection in firestore.
 * @param {object} data The data to submit to firestore.
 */
export async function addData(collectionName, data) {
  try {
    const docRef = await addDoc(collection(db, collectionName), data);
    return docRef.id;
  } catch (err) {
    // TODO: handle error
    console.log("debug err", err);
  }
}

/**
 * Get data from a firestore collection.
 *
 * @param {string} collectionName The name of the collection in firestore.
 */
export async function getData(collectionName) {
  return await getDocs(collection(db, collectionName))
    .then((res) => {
      return res.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    })
    .catch((err) => {
      // TODO: handle error
      console.log("debug err", err);
    });
}

/**
 * Get data from firestore where ID is.
 *
 * @param {string} collectionName The name of the collection in firestore.
 * @param {string} id The ID of the item you want to get.
 */
export async function getDataByID(collectionName, id) {
  return await getDocs(collection(db, collectionName))
    .then((res) => {
      const allData = res.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      let dataByID = {};
      allData.forEach((data) => {
        if (data.id === id) {
          dataByID = data;
        }
      });

      return dataByID;
    })
    .catch((err) => {
      // TODO: handle error
      console.log("debug err", err);
    });
}

/**
 * Get data from firestore where userId is.
 *
 * @param {string} collectionName The name of the collection in firestore.
 * @param {string} id The ID of the item you want to get.
 */
export async function getDataByUserID(collectionName, userId) {
  return await getDocs(collection(db, collectionName))
    .then((res) => {
      const allData = res.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      let dataByUserID = [];
      allData.forEach((data) => {
        if (data.userId === userId) {
          dataByUserID.push(data);
        }
      });

      return dataByUserID;
    })
    .catch((err) => {
      // TODO: handle error
      console.log("debug err", err);
    });
}
