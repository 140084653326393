import { useState } from "react";
import { useTranslation } from "react-i18next";

import Input from "../../components/Form/Input/Input";
import Select from "../../components/Form/Select/Select";
import { categories } from "../../constants/Categories";
import { countries } from "../../constants/Countries";
import { addData } from "../../dao/firestore";
import useUser from "../../hooks/useUser";
import { destinationsCollection } from "../../constants/Firebase";
import Textarea from "../../components/Form/Textarea/Textarea";
import Toggle from "../../components/Form/Toggle/Toggle";
import LinksInput from "../../components/Form/LinksInput/LinksInput";
import { useNavigate } from "react-router-dom";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { defaultMapCenter, priceMapping } from "../../constants/Map";
import { getDataByPlaceID } from "../../dao/googleMaps";

/**
 * The create destination page.
 */
export default function CreateDestinationPage() {
  const { t } = useTranslation();
  const { user } = useUser();
  const navigate = useNavigate();

  const [destinationName, setDestinationName] = useState("");
  const [address, setAddress] = useState("");
  const [price, setPrice] = useState(priceMapping.PRICE_LEVEL_FREE);
  const [visited, setVisited] = useState(false);
  const [destinationDescription, setDestinationDescription] = useState("");
  const [category, setCategory] = useState(
    categories[Object.keys(categories)[0]]
  );
  const [country, setCountry] = useState(countries[Object.keys(countries)[0]]);
  const [links, setLinks] = useState([]);
  const [marker, setMarker] = useState(null);

  /**
   * Submit the destination.
   */
  async function submitDestination() {
    if (destinationName && destinationDescription && category && country) {
      await addData(destinationsCollection, {
        name: destinationName,
        location: address,
        description: destinationDescription,
        price,
        category,
        visited,
        country,
        userId: user.uid,
        links,
        marker,
        imageUrl:
          "https://thumbs.dreamstime.com/b/incredibly-beautiful-sunset-sun-lake-sunrise-landscape-panorama-nature-sky-amazing-colorful-clouds-fantasy-design-115177001.jpg",
      }).then((res) => {
        navigate(`/destination/${res}`);
      });
    } else {
      // TODO: show error message.
      console.log("debug invalid form");
    }
  }

  /**
   * Do actions when a user clicks on the map.
   *
   * @param {object} event Event object.
   */
  async function onMapClick(event) {
    setMarker({ lat: event.latLng.lat(), lng: event.latLng.lng() });

    if (event?.placeId) {
      await getDataByPlaceID(event?.placeId).then((res) => {
        setDestinationName(res?.displayName?.text);
        setAddress(res?.formattedAddress);
        setDestinationDescription(res?.editorialSummary?.text);
        setPrice(res?.priceLevel ?? 0);
        setCountry(
          res?.addressComponents?.find((addressComponent) =>
            addressComponent.types.includes("country")
          )?.shortText ?? ""
        );
      });
    }
  }

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  return (
    <div className="create-destination">
      {isLoaded && (
        <div className="create-destination__map">
          <GoogleMap
            mapContainerStyle={{ width: "100%", height: "400px" }}
            center={defaultMapCenter}
            zoom={3}
            onClick={(e) => onMapClick(e)}
          >
            {marker && <Marker position={marker} />}
          </GoogleMap>
        </div>
      )}
      <div className="create-destination__form">
        <Input
          label={t("destinationName")}
          type="text"
          name="destination-name"
          id="destination-name"
          onChange={setDestinationName}
          value={destinationName}
          required
        />
        <Input
          label={t("address")}
          type="text"
          name="address"
          id="address"
          onChange={setAddress}
          value={address}
          required
        />
        <Select
          label={t("price")}
          name="price"
          id="price"
          onChange={setPrice}
          values={priceMapping}
          value={price}
          required
        />
        <Select
          label={t("category")}
          name="category"
          id="category"
          onChange={setCategory}
          values={categories}
          required
        />
        <Select
          label={t("country")}
          name="country"
          id="country"
          onChange={setCountry}
          value={country}
          values={countries}
          required
        />
        <Toggle
          checked={visited}
          setChecked={setVisited}
          label={t("visited")}
        />
        <Textarea
          label={t("description")}
          type="text"
          name="destination-description"
          id="destination-description"
          onChange={setDestinationDescription}
          value={destinationDescription}
          className="create-destination__form--description"
          rows="3"
          required
        />
        <LinksInput links={links} setLinks={setLinks} />
      </div>
      <div className="create-destination__submit">
        <button className="btn btn-primary" onClick={() => submitDestination()}>
          {t("create")}
        </button>
      </div>
    </div>
  );
}
