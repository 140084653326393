import { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { auth } from "../../../firebase";
import Input from "../../Form/Input/Input";
import useUser from "../../../hooks/useUser";

/**
 * The login form.
 */
export default function LoginForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setUser } = useUser();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  /**
   * Log in the user.
   */
  function onLogin() {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        setUser(userCredential.user);
        navigate("/");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(errorCode, errorMessage);
      });
  }

  return (
    <>
      <form className="login-form">
        <Input
          label={t("emailAddress")}
          type="email"
          name="email"
          id="email-address"
          onChange={setEmail}
          required
        />

        <Input
          label={t("password")}
          type="password"
          name="password"
          id="password"
          onChange={setPassword}
          required
        />
      </form>
      <button className="button button-primary" onClick={onLogin}>
        {t("login")}
      </button>
    </>
  );
}
