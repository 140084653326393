import { createContext, useEffect, useState } from "react";
import Cookies from "universal-cookie";

export const UserContext = createContext(null);

/**
 * User context provider
 *
 * @param {JSX.Element} children The children.
 */
export default function UserProvider({ children }) {
  const cookies = new Cookies();
  const [user, setUser] = useState(cookies.get("user") ?? null);


  useEffect(() => {
    if (user) {
      const cookies = new Cookies();

      cookies.set("user", user, {
        path: "/",
        maxAge: 24 * 60 * 60,
      });
    }
  }, [user]);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
}
