export const enTranslations = {
  emailAddress: "Email address",
  password: "Password",
  login: "Login",
  noAccount: "No account yet?",
  signUp: "Sign up",
  haveAccount: "Already have an account?",
  signIn: "Login",
  account: "Account",
  create: "Create",
  itinerary: "Itinerary",
  destination: "Destination",
  destinations: "Destinations",
  allDestinations: "See all destinations",
  destinationName: "Destination name",
  address: "Address",
  price: "Price",
  visited: "Visited",
  category: "Categorie",
  country: "Country",
  description: "Description",
  linkTitle: "Link title",
  linkUrl: "Link URL",
  addLink: "Add link",
};
