import { Navigate, Outlet } from "react-router-dom";

import useUser from "../../../hooks/useUser";

/**
 * Protected route component.
 *
 * @param {JSX.Element} children The children.
 */
export default function ProtectedRoute({ children }) {
  const { user } = useUser();

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  return children ? children : <Outlet />;
}
