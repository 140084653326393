import { useState } from "react";

/**
 * A toggle for for example a form.
 */
export default function Toggle({ label, checked, setChecked }) {
  const [toggleChecked, setToggleChecked] = useState(checked);

  return (
    <div className="toggle">
      <p className="toggle__text">{label}</p>
      <label className="toggle__wrapper">
        <input
          className="toggle__wrapper--input"
          type="checkbox"
          checked={toggleChecked}
          onChange={() => {
            setToggleChecked(!toggleChecked);
            setChecked(!toggleChecked);
          }}
        />
        <span className="toggle__wrapper--switch" />
      </label>
    </div>
  );
}
