import { Link } from "react-router-dom";

import ChevronRightIcon from "../../Icons/ChevronRightIcon";
import colors from "../../../assets/styles/general/_colors.scss";

/**
 * A wrapper for a heading.
 *
 * @param {string} title The title.
 * @param {string} linkText The text to show in the link.
 * @param {string} linkUrl The URL to redirect to.
 */
export default function HeadingWrapper({ title, linkText, linkUrl }) {
  return (
    <div className="heading-wrapper">
      <h3 className="heading-wrapper__title">{title}</h3>
      {linkText && linkUrl && (
        <Link to={linkUrl} className="heading-wrapper__link">
          {linkText}
          <ChevronRightIcon fillColor={colors.quaternaryColor} />
        </Link>
      )}
    </div>
  );
}
