import { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Header from "../Header/Header";
import PlusIcon from "../../Icons/PlusIcon";
import colors from "../../../assets/styles/general/_colors.scss";

/**
 * The default layout component.
 *
 * @param {JSX.Element} children The children.
 */
export default function Layout({ children }) {
  const { t } = useTranslation();

  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <>
      <Header
        logoUrl="https://www.getautismactive.com/wp-content/uploads/2021/01/Test-Logo-Circle-black-transparent.png"
        logoAlt="Destinations logo"
      >
        <div className="header-button">
          <button
            className="btn btn-secondary"
            onClick={() => setShowDropdown(!showDropdown)}
          >
            <PlusIcon fillColor={colors.whiteColor} />
            {t("create")}
          </button>
          {showDropdown && (
            <div className="header-button__dropdown">
              <Link
                to="/create-itinerary"
                className="header-button__dropdown--item"
                onClick={() => setShowDropdown(false)}
              >
                {t("itinerary")}
              </Link>
              <Link
                to="/create-destination"
                className="header-button__dropdown--item"
                onClick={() => setShowDropdown(false)}
              >
                {t("destination")}
              </Link>
            </div>
          )}
        </div>
        <Link to="/account">{t("account")}</Link>
      </Header>
      <div className="layout">
        {children}
      </div>
    </>
  );
}
